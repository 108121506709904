import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import styled from "styled-components";
import { setActiveCheckIn } from "../common/slices/checkIn";
import { deleteUser, selectActiveUser, selectUser, updateUser } from "../common/slices/user";
import theme from '../common/theme';
import { setModalState } from "../slices/app";
import { ButtonBase, RedButton } from "./Buttons";
import FormPhoneNumber from "./FormPhoneNumber";
import FormSelectCompany from "./FormSelectCompany";
import FormSelectERP from "./FormSelectERP";
import FormSelectProtocol from "./FormSelectProtocol";
import FormSelectRole from "./FormSelectRole";
import FormSelectSatelliteDeviceType from './FormSelectSatelliteDeviceType';
import FormTextInput from "./FormTextInput";
import Modal from './Modal';
import Row from './Row';
import { H1 } from "./Typography";
import ModalSetPassword from "./ModalSetPassword";
import { useState } from "react";
import FormCheckbox from "./FormCheckbox";
import ModalShiftSchedule from './ModalShiftSchedule';
import ModalEmergencyContacts from "./ModalEmergencyContacts";
import FormTextArea from "./FormTextArea";
import validateEmail from "../common/service/validateEmail";

const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`

const Header = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
    align-items: center;
`

const Footer = styled.div`
    display: flex;
    gap: 0.5rem;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    padding-top: 1rem;
    border-top: 1px solid rgb(204,204,204);
`

const marshall = (user) => ({
    ...user,
    companyId: { value: user?.company?.id, label: user?.company?.name },
    erpAreaId: { value: user?.erpArea?.id, label: user?.erpArea?.name }
})

const unmarshall = (user) => ({
    ...user,
    companyId: user.companyId.value,
    erpAreaId: user.erpAreaId.value,
})

const ModalAddUser = ({ show, onClose }) => {
    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [showSchedule, setShowSchedule] = useState(false);
    const [showContacts, setShowContacts] = useState(false);
    const user = useSelector(selectUser);
    const activeUser = useSelector(selectActiveUser);
    const dispatch = useDispatch();
    const nav = useNavigate();

    const onSaveClick = async (values) => {
        try {
            await dispatch(updateUser(unmarshall(values)))
            onClose();
        } catch (ex) {
            console.log(ex);
        }
    }

    const onDelete = async () => {
        const confirmed = window.confirm(`Are you sure you want to delete ${activeUser.firstName} ${activeUser.lastName}?`)
        if (confirmed) {
            dispatch(deleteUser(activeUser))
            onClose();
        }
    }

    const onSetPassword = () => {
        // dispatch(setModalState({ modalName: 'SET_PASSWORD', show: true }))
        setShowPasswordModal(true);
    }

    const onShowSchedule = () => {
        // dispatch(setModalState({ modalName: 'SET_PASSWORD', show: true }))
        setShowSchedule(true);
    }

    const onShowContacts = () => {
        // dispatch(setModalState({ modalName: 'SET_PASSWORD', show: true }))
        setShowContacts(true);
    }

    const onFindOnMap = () => {
        dispatch(setActiveCheckIn(activeUser.email));
        dispatch(setModalState({ modalName: 'USER_DETAILS', show: true }));
        nav('/');
    }

    const {
        control,
        handleSubmit,
        formState: { errors, isSubmitting, isValid, isDirty },
        setValue,
        watch,
    } = useForm({
        values: marshall(activeUser)
    })

    const fieldProps = { control, errors }
    const companyId = useWatch({ name: 'companyId', control });
    const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    const role = watch('role');
    console.log(errors);

    return (
        <Modal
            show={show}
            onClose={() => { if (!isDirty) { onClose() } }}
        >
            <Container>
                <Header>
                    <H1>{activeUser ? 'Update User' : 'Add New User'}</H1>
                    <ButtonBase onClick={onClose}><FontAwesomeIcon icon={faTimesCircle} size={'2x'} /></ButtonBase>
                </Header>
                <Row>
                    <FormTextInput {...fieldProps} name="firstName" placeholder="First Name" label="First Name" rules={{ required: true }} />
                    <FormTextInput {...fieldProps} name="lastName" placeholder="Last Name" label="Last Name" rules={{ required: true }} />
                </Row>
                <Row>
                    <FormTextInput {...fieldProps} name="email" placeholder="Email Address" label="Email Address" disabled={activeUser} rules={{
                        required: true,
                        pattern: {
                            value: re,
                            message: "Invalid email"
                        },
                        validate: activeUser ? true : validateEmail,
                    }} />
                    <FormPhoneNumber {...fieldProps} name="phoneNumber" placeholder="Phone Number" label="Phone Number" rules={{ required: true }} defaultCountry="CA" />
                    <FormSelectCompany {...fieldProps} rules={{ required: true }} role={user?.role} companyId={user?.companyId} />
                </Row>
                <Row>
                    <FormSelectRole {...fieldProps} rules={{ required: true }} disabled={activeUser?.role === "OPERATOR"} />
                    <FormSelectProtocol {...fieldProps} rules={{ required: true }} />
                    {role === 'SUPERVISOR' && <FormCheckbox {...fieldProps} name="excludeShiftReport" label="Exclude Shift Reports" />}
                </Row>
                <FormSelectERP name="erpAreaId"  {...fieldProps} companyId={companyId.value} rules={{ required: true }} setValue={setValue} />
                <FormTextArea name="contactNotes" placeholder="Enter notes for this user, ie. medical conditions, do not contact, etc" label="User Notes" {...fieldProps} />
                <Row>
                    <FormSelectSatelliteDeviceType {...fieldProps} />
                    <FormTextInput {...fieldProps} name="satelliteDeviceId" placeholder="Satellite Device ID" label="Satellite Device ID" />
                    <FormPhoneNumber {...fieldProps} name="satelliteDeviceSMS" placeholder="Satellite Device Phone Number" label="Satellite Device Phone Number" />
                </Row>
                <Footer>
                    <RedButton onClick={handleSubmit(onSaveClick)} disabled={isSubmitting || !isValid}>Save</RedButton>
                    {activeUser && <RedButton onClick={onDelete} disabled={isSubmitting}>Delete</RedButton>}
                    {activeUser && <RedButton onClick={onSetPassword} disabled={isSubmitting} >Set Password</RedButton>}
                    {activeUser && <RedButton onClick={onFindOnMap} disabled={isSubmitting}>Find on Map</RedButton>}
                    {activeUser && <RedButton onClick={onShowContacts} disabled={isSubmitting}>Emergency Contacts</RedButton>}
                    {activeUser && <RedButton onClick={onShowSchedule} disabled={isSubmitting} style={{ marginRight: 'auto' }}>Schedule</RedButton>}
                    <ButtonBase onClick={onClose}>Cancel</ButtonBase>
                </Footer>
                {showPasswordModal && <ModalSetPassword show onClose={() => setShowPasswordModal(false)} />}
                {showSchedule && <ModalShiftSchedule show onClose={() => setShowSchedule(false)} />}
                {showContacts && <ModalEmergencyContacts show onClose={() => setShowContacts(false)} />}
            </Container>
        </Modal>
    )
}

export default ModalAddUser