import { API } from "aws-amplify";
import { searchUsers } from "../graphql/customQueries";

export default async (search, companyId, pageSize = 13, nextToken) => {
    try {
        const filter = {
            or: [
                { firstName: { wildcard: "*" + search + "*" } },
                { lastName: { wildcard: "*" + search + "*" } },
                { email: { wildcard: "*" + search + "*" } },
                { phoneNumber: { wildcard: "*" + search + "*" } },
                { satelliteDeviceId: { wildcard: "*" + search + "*" } },
                { companyId: { eq: search } },
            ],
        };

        if (companyId) {
            filter.and = [{ companyId: { match: companyId } }];
        }

        const {
            data: { searchUsers: users },
        } = await API.graphql({
            query: searchUsers,
            variables: {
                filter,
                limit: pageSize,
                nextToken,
            },
        });
        return users;
    } catch (e) {
        console.error("failed to list users", e);
        return false;
    }
};